import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const OrderNumber = styled.h4`
	font-weight: 700;
	margin-block: 2rem;
`;

export const TicketImgContainer = styled.div`
	width: 18px;
	display: inline-block;
	margin-right: 12px;
`;

export const ListItemLabel = styled(Col)`
	color: #64686C;
	font-weight: 600;
	font-size: 1.05rem;
`;

export const ListItemValue = styled(Col)`
	font-size: 1.5rem;
	font-weight: 500;
	text-align: right;
`;

export const Divider = styled.div`
	height: 1px;
	border-top: 1.5px dashed var(--bs-gray-500);
`;

export const TotalLabel = styled(Col)`
	font-size: 1.5rem;
	font-weight: 600;
`;

export const TotalValue = styled(Col)`
	font-size: 2.25rem;
	text-align: right;
	font-weight: 600;
	color: #DC7013;
`;
