import styled from 'styled-components';

export const ScrollIntoViewContainer = styled.div`
	height: calc(100vh - 115px);
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;
