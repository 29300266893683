import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
	OrderNumber,
	TicketImgContainer,
	ListItemLabel,
	ListItemValue,
	Divider,
	TotalLabel,
	TotalValue,
} from './Summary.styled';
import iconSet from "./icomoonSelection.json"
import IcomoonReact from 'icomoon-react'

const Summary = () => {
	const [orderNum, setOrderNum] = useState('');
	const [vouchers, setVouchers] = useState([]);
	const [amount, setAmount] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [outlet, setOutlet] = useState('');

	const onSummaryMessageReceived = (event) => {
		console.log('summary event', event);
		if (event.origin === 'http://localhost:3000' || !event.data) return;

		let data = JSON.parse(event.data);

		if (!data.fspos) return;

		if (data.fspos.orderlines) {
			data = data.fspos.orderlines;

			setAmount(
				data.reduce((prev, curr) => (prev += curr.price * curr.quantity), 0)
			);
			console.log('databefore', data);

			const discountArr = data.filter((i) => i.category === 'Vouchers');
			if (discountArr.length > 0) {
				setDiscount(
					discountArr.reduce((prev, curr) => (prev += curr.price), 0)
				);
			} else {
				setDiscount(0);
			}

			let vouchers = discountArr.map((discount) => ({
				id: discount.id,
				display_name: discount.display_name,
				amount: discount.price,
			}));

			setVouchers(vouchers);
			// console.log( "data 2" , vouchers);
		} else if (data.fspos.orderNum) {
			setOrderNum('#' + data.fspos.orderNum);
		} else if (data.fspos.outlet) {
			setOutlet(data.fspos.outlet);
		} else if (data.fspos.action && data.fspos.action === 'reset') {
			setOrderNum('');
			setAmount(0);
			setDiscount(0);
			setVouchers([]);
		}

		console.log('data', data);
	};

	useEffect(() => {
		// initiate the event handler
		window.addEventListener('message', onSummaryMessageReceived);

		return () => {
			window.removeEventListener('message', onSummaryMessageReceived);
		};
	});

	return (
		<Container className="px-4 d-relative">
			<div style={{ marginTop: '30%' }} className="mx-5">
				<Row>
					<OrderNumber>Order {orderNum}</OrderNumber>
				</Row>

				<Row className="my-3 align-items-center">
					<ListItemLabel xs={6}>Subtotal</ListItemLabel>
					<ListItemValue xs={6}>{(amount - discount).toFixed(2)}</ListItemValue>
				</Row>

				{vouchers.map((voucher) => (
					<Row className="my-3 align-items-center" key={voucher.id}>
						<ListItemLabel xs={6}>{voucher.display_name}</ListItemLabel>
						<ListItemValue xs={6}>
							<TicketImgContainer>
								<IcomoonReact iconSet={iconSet} color="#707070" size={22} icon="ticket" />
							</TicketImgContainer>
							{voucher.amount.toFixed(2).replace('-', '')}
						</ListItemValue>
					</Row>
				))}
				<Row>
					<Divider />
				</Row>

				<Row className="my-3 align-items-center">
					<TotalLabel xs={6}>Total</TotalLabel>
					<TotalValue xs={6}>{( amount < 0 ? 0 : amount).toFixed(2)}</TotalValue>
				</Row>
			</div>

			<div style={{ position: 'absolute', bottom: '5%' }}>
				<Row className="mx-4" style={{
							fontSize: '1.15rem',
							color: 'gray'
						}}>
					<Col>{outlet || ''}</Col>
				</Row>
			</div>
		</Container>
	);
};

export default Summary;
