import ItemList from './components/ItemList/ItemList';
import Summary from './components/Summary/Summary';
// import './App.css';
import AppContainer from './layouts/AppContainer';
import { Col, Row } from 'react-bootstrap';

const App = () => {
	return (
		<AppContainer>
			<Row>
				<Col
					style={{ backgroundColor: 'var(--bs-gray-100)', height: '100vh' }}
					xs={8}
				>
					<ItemList />
				</Col>
				<Col xs={4} style={{ height: '100vh' }}>
					<Summary />
				</Col>
			</Row>
		</AppContainer>
	);
};
export default App;
