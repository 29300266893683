import React from 'react';
import { Container } from 'react-bootstrap';

const AppContainer = ({ children }) => {
	return (
		<Container fluid style={{ maxHeight: '100vh', overflow: 'hidden' }}>
			{children}
		</Container>
	);
};

export default AppContainer;
