import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ScrollIntoViewContainer } from './ItemList.styled';

const ItemList = () => {
	const [products, setProducts] = useState([]);

	const onLinesMessageReceived = (event) => {
		console.log('display lines event', event);

		if (event.origin === 'http://localhost:3000' || !event.data) return;

		let data = JSON.parse(event.data);

		if (!data.fspos) return;

		if (data.fspos.orderlines) {
			data = data.fspos.orderlines;
			let discountFilter = data.filter((e) => e.category !== 'Vouchers');
			setProducts(discountFilter);
			console.log('lengthleft', data);
		} else if (data.fspos.action && data.fspos.action === 'reset') {
			setProducts([]);
		}
	};

	const scrollViewContainer = useRef();

	useEffect(() => {
		window.addEventListener('message', onLinesMessageReceived);

		return () => {
			window.removeEventListener('message', onLinesMessageReceived);
		};
	}, []);

	useEffect(() => {
		if (
			scrollViewContainer?.current?.scrollHeight >
			scrollViewContainer?.current?.offsetHeight
		) {
			scrollViewContainer?.current.scrollTo({
				top:
					scrollViewContainer.current.scrollHeight +
					scrollViewContainer.current.offsetTop,
				behavior: 'smooth',
			});
		}
	}, [products]);

	return (
		<Container className="py-4 px-5">
			<Row
				className="mt-3 fw-600"
				style={{
					color: '#54585B'
				}}
			>
				<Col xs={1} className="text-center">
					Qty
				</Col>
				<Col xs={7}>Item</Col>
				<Col xs={2} className="text-center">
					Unit Price
				</Col>
				<Col xs={2} className="text-center">
					Total Price
				</Col>
			</Row>
			<ScrollIntoViewContainer
				id="scrollViewContainer"
				// className="mt-3"
				ref={scrollViewContainer}
			>
				{products.map((product, i) => (
					<Row
						key={product.id}
						className="py-2 my-2 bg-white"
						style={{
							fontSize: '1.1rem',
						}}
					>
						<Col xs={1} className="text-center">
							{product.quantity}
						</Col>
						<Col xs={7} className="fw-500">{product.display_name}</Col>
						<Col xs={2} className="text-center">
							{product.price.toFixed(2)}{' '}
						</Col>
						<Col xs={2} className="text-center fw-500">
							{(product.price * product.quantity).toFixed(2)}
						</Col>
					</Row>
				))}
			</ScrollIntoViewContainer>
		</Container>
	);
};

export default ItemList;
